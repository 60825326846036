import React from 'react';
import './App.css';
import mojs from '@mojs/core'

export default class App extends React.Component {

  componentDidMount() {
    /* ADD CUSTOM SHAPE SOMEWHERE IN YOUR CODE */
    class Heart extends mojs.CustomShape {
      getShape () { return '<path d="M92.6 7.4c-10-9.9-26-9.9-35.9 0l-4.4 4.3a3.4 3.4 0 0 1-4.7 0l-4.3-4.3c-10-9.9-26-9.9-35.9 0a25 25 0 0 0 0 35.5l22.4 22.2 13.5 13.4a9.5 9.5 0 0 0 13.4 0L70.2 65 92.6 43a25 25 0 0 0 0-35.5z"/>'; }
      getLength () { return 292.110107421875; } // optional
    }
    mojs.addShape( 'heart', Heart ); // passing name and Bubble class

    /* USE CUSTOM SHAPE */
// now it is available on mojs.Shape constructor as usual
    const heart = new mojs.Shape({
      parent:           '#mojsshape',
      shape:            'heart',
      fill:             'none',
      stroke:           'crimson',
      strokeDasharray:  '100%',
      strokeDashoffset: { '-100%' : '100%' },
      y:               -20,
      duration:         5000,
      repeat: 20
    });
    heart.play();
  }

  render() {
    return (
        <div className="App">
          <header className="App-header">
            <div id="mojsshape"/>
            <div className="text">
              <div>
                The Drakon Solutions UG is currently still working on the Homepage.
              </div>
              <div>
                Please stand by. Thank you for your interest
              </div>
            </div>
            <div id="mojsshape"/>
          </header>
        </div>
    );
  }
}
